import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Warm up:`}</em></p>
    <p><em parentName="p">{`Sotts Press 4×4`}</em></p>
    <p><em parentName="p">{`Overhead Band Pull Aparts 4×10`}</em></p>
    <p>{`then,`}</p>
    <p>{`Power Snatch 5×2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 each of:`}</p>
    <p>{`OHS (115/75)(RX+ 135/95)`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Burpees Over Rower`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      